import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Layout from "../../../components/layout";
import OrderButton from "../../../components/orderbutton";
import P5Showcase from "../../../components/product/indoor/p5Showcase";
import ProductTable from "../../../components/productTable";
import SEO from "../../../components/seo";
import Content from "../../../content/produk/indoor/P5/Product-Page.json";
import ProductImage from "../../../images/product/indoor/P5.0/Product-Summary-P5-Indoor.jpg";

const p5 = ({ siteTitle }) => {
    const headerStyle = {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        paddingBottom: "20px",
        position: "relative",
        color: "#2d405f",
    };
    return (
        <Layout>
            <SEO
                title={Content.seo.title}
                description={Content.seo.description}
                lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1 style={headerStyle}>
                            P5 Indoor LED Display Module
                        </h1>
                        <Row>
                            <Col sm={true}>
                                <Image src={ProductImage} fluid />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2>Product Summary</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    P.5 indoor merupakan layar LED penuh warna
                                    dengan kepadatan pixel yang tinggi sehingga
                                    yang cocok digunakan pada ruang pertemuan,
                                    ruang ibadah, creative space, sistem
                                    informasi pada transportasi publik (bandara,
                                    stasiun MRT, pelabuhan), stage indoor, media
                                    belajar mengajar dan acara-acara dalam ruang
                                    (indoor) lainnya. P.5 memiliki kualitas
                                    sebagai berikut:
                                </p>
                                <ul style={{ textAlign: "left" }}>
                                    <li>Ringan dan warna dapat disesuaikan.</li>
                                    <li>
                                        Kepadatan pixel yang tinggi sehingga
                                        menghasilkan visual yang detail dan
                                        menarik.
                                    </li>
                                    <li>
                                        Media yang
                                        inovatif,interaktif,informatif untuk
                                        berbagai keperluan.
                                    </li>
                                    <li>Struktur yang kuat dan tahan lama.</li>
                                </ul>
                                <p>
                                    <OrderButton
                                        messageText={Content.messageText}
                                    />
                                </p>
                            </Col>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Unit Module
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"Unit Module"}
                                    tableData={Content.moduleUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        LED Cabinet Unit
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"LED Unit Cabinet"}
                                    tableData={Content.cabinetUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Contoh Pemasangan P5 Indoor
                                    </h2>
                                </div>
                                <P5Showcase />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

p5.propTypes = {
    siteTitle: PropTypes.string,
};

p5.defaultProps = {
    siteTitle: ``,
};

export default p5;
